"use strict"

import $ from "jquery";

let scroll = require('malihu-custom-scrollbar-plugin-3.1.7');
let scrollMouse = require('jquery-mousewheel');

function toggleNavPrimary() {

    let $_nav = $('.cmp-nav-primary .ux-nav-bar');
    let $_nav_overlay = $('.cmp-nav-primary .ux-nav-overlay');
    let $_nav_map = $('.cmp-nav-primary .ux-nav-map');
    let $_nav_panel = $('.cmp-nav-primary .ux-nav-panel');
    let $_nav_trigger = $('.cmp-nav-primary .ux-nav-trigger');

    let $_nav_collapsed = $('.ui-nav-bar-collapsed');

    $_nav_trigger.on('click', function () {

        let is_collapsed = $_nav_collapsed.hasClass('ui-nav-bar-collapsed');

        if (is_collapsed) {
            expandMenu($_nav, $_nav_panel, $_nav_map, $_nav_overlay);
        } else {
            collapseMenu($_nav, $_nav_panel, $_nav_map, $_nav_overlay);
        }
    });

    $_nav_overlay.on('click', function () {

        collapseMenu($_nav, $_nav_panel, $_nav_map, $_nav_overlay);
    });
}


function expandMenu($_nav, $_nav_panel, $_nav_map, $_nav_overlay) {
    $_nav.removeClass('ui-nav-bar-collapsed').addClass('ui-nav-bar-expanded');
    $_nav_map.removeClass('ui-nav-map-collapsed').addClass('ui-nav-map-expanded');
    $_nav_panel.removeClass('ui-nav-panel-collapsed').addClass('ui-nav-panel-expanded');
    $_nav_overlay.addClass('is-active');
    $_nav.find('.ux-nav-primary-trigger-text').text('Cerrar');

}


function collapseMenu($_nav, $_nav_panel, $_nav_map, $_nav_overlay) {
    $_nav.removeClass('ui-nav-bar-expanded').addClass('ui-nav-bar-collapsed');
    $_nav_map.removeClass('ui-nav-map-expanded').addClass('ui-nav-map-collapsed');
    $_nav_panel.removeClass('ui-nav-panel-expanded').addClass('ui-nav-panel-collapsed');
    $_nav_overlay.removeClass('is-active');
    $_nav.find('.ux-nav-trigger-text').text('Menú');
}

toggleNavPrimary();


$('.ux-nav-item-group').on('click', function () {

    toggleMenuGroup(this);
});

function toggleMenuGroup(elem) {

    let $_elem = $(elem);

    let $menuItems = $_elem.find('.ux-menu-group .menu-item');
    let elementSize = $menuItems.outerHeight();
    let elementQ = $menuItems.length;
    let expandedSize = elementSize * elementQ;

    if($_elem.hasClass('ui-nav-item-group-expanded')){

        $_elem.removeClass('ui-nav-item-group-expanded');
        $_elem.find('.ux-menu-group').removeClass('ui-nav-menu-group-expanded');
        $('.ux-menu-group').css('height', 0)

    }else{

        $_elem.addClass('ui-nav-item-group-expanded')
        $_elem.find('.ux-menu-group').addClass('ui-nav-menu-group-expanded')
        $_elem.find('.ux-menu-group').css('height', expandedSize)
    }

}


// Inicio barra de Scroll del Navbar
$(window).on("load", function () {

    $(".ux-nav-panel-scrollbar").mCustomScrollbar({
        mouseWheel: {
            enable: true,
            preventDefault: true,
        },
        theme: 'minimal'
    });
});
