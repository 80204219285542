"use strict";

import "owl.carousel";
import $ from "jquery";


// spiner loader

let loader = function () {

	setTimeout(function () {

		if ($('.site-loader').length > 0) {

			$('.site-loader').removeClass('show');

		}

	}, 500);

};

loader();


// carrusel

$('.owl-carousel').owlCarousel({
	loop: true,
	autoplay: true,
	dots: true,
	nav: false,
	items: 1,
	URLhashListener: false,
	slideTransition: 'ease-out',
	autoplayTimeout: 10000
});


// Tamaños de hero

let fullHeight = function () {
	$('.ux-fullheight').css('min-height', $(window).height());

	$(window).resize(function () {
		$('.ux-fullheight').css('min-height', $(window).height());
	});

	return false;
};
fullHeight();


let mediumHeight = function () {
	$('.ux-mediumheight').css('min-height', ($(window).height() * 70) / 100);

	$(window).resize(function () {
		$('.ux-mediumheight').css('min-height', ($(window).height() * 70) / 100);
	});

	return false;
};
mediumHeight();


let smallHeight = function () {
	$('.ux-smallheight').css('min-height', ($(window).height() * 45) / 100);

	$(window).resize(function () {
		$('.ux-smallheight').css('min-height', ($(window).height() * 45) / 100);
	});

	return false;
};
smallHeight();

let extraSmallHeight = function () {
    $('.ux-extraSmallHeight').css('min-height', ($(window).height() * 27) / 100);

    $(window).resize(function () {
        $('.ux-extraSmallHeight').css('min-height', ($(window).height() * 27) / 100);
    });

    return false;
};
extraSmallHeight();

/*
    Carga diferida de videos youtube.
    Esto crea un iframe de youtube a partir de un div con la clase reproductor y un atributo data-id con la id del iframe,
    al dar click/hover en el contenedor, se creará el iframe mediante JS.

    Nota: la miniatura del video es automática (src="//i.ytimg.com/vi/' + id + '/hqdefault.jpg"), pero se puede personalizar para cargar una personalizada.
*/

(function() {
    var v = document.getElementsByClassName("reproductor");
    for (var n = 0; n < v.length; n++) {
        var p = document.createElement("div");
        p.innerHTML = labnolThumb(v[n].dataset.id);
        p.setAttribute("style","height:100%;");
        p.addEventListener('mouseover', labnolIframe);
        v[n].appendChild(p);
    }
})();

function labnolThumb(id) {
    return '<img class="imagen-previa image" src="//i.ytimg.com/vi/' + id + '/hqdefault.jpg" alt="Miniatura de vídeo"><div class="youtube-play"></div>';
}

function labnolIframe() {
    var iframe = document.createElement("iframe");
    iframe.setAttribute("width","100%");
    iframe.setAttribute("height","100%");
    iframe.setAttribute("src", "//www.youtube.com/embed/" + this.parentNode.dataset.id + "?&autohide=2&border=0&wmode=opaque&enablejsapi=1&controls=1&showinfo=0");
    iframe.setAttribute("frameborder", "0");
    iframe.setAttribute("allow","autoplay;");
    iframe.setAttribute("id", "youtube-iframe");
    this.parentNode.replaceChild(iframe, this);
}



//learning modulo contenidos del curso, parsea el tipo ul/ol y lo anida para usarlo con estilos propios

$(window).on("load", function () {

	let pathname = window.location.pathname.toString();

	if (!pathname.includes('learning') && !pathname.includes('emprende')) {

		return false;
	}
	let listCnt = $('.ux-list-cnt')
	let list = listCnt.find('ol');
	let itemList = $(list[0]).children('li');

	for (let itemListElement of itemList) {
		itemListElement = $(itemListElement);

		//meter la altura en data-height y controlar el cambio de altura con este parametro
		let subItems = itemListElement.children('ol');

		if (!subItems.length) {
			continue;
		}

		subItems.attr('data-height', subItems.height());
		subItems.addClass('ui-collapsed')
		itemListElement.addClass('ui-collapsable ux-dropdown-list');
	}

	listCnt.on('click', '.ux-dropdown-list', function () {
		let item = $(this);
		let itemList = item.children('ol');

		if (!itemList.hasClass('ui-collapsed')) {

			itemList.css({height: itemList.height()});
			item.removeClass('ui-expanded');
			itemList.addClass('ui-collapsed');

			setTimeout(function () {
				itemList.css({height: 0});
			},1);

			return false;

		} else {

			item.addClass('ui-expanded');
			itemList.css({overflow: 'hidden'});
			itemList.css({height: 'auto'});
			itemList.attr('data-height', itemList.height());
			itemList.css({height: 0});

			setTimeout( function () {
				itemList.css({height: itemList.attr('data-height')});
				itemList.removeClass('ui-collapsed');
			}, 1);

			setTimeout(function () {
				itemList.css({height: 'auto'});
			}, 455);
		}
	});
});

document.addEventListener('DOMContentLoaded', function () {
    setupCookieBar();
  });














